import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const isDataAtualOu30DiasAntes = () => {
    const dataAtual = new Date()

    const data30DiasAntes = new Date()
    data30DiasAntes.setDate(data30DiasAntes.getDate() - 30)

    return item.dataLancado && (new Date(item.dataLancado).toDateString() === dataAtual.toDateString() || (new Date(item.dataLancado) >= data30DiasAntes && new Date(item.dataLancado) <= dataAtual))
  }

  const isNovo = isDataAtualOu30DiasAntes()

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  return {
    isNovo,
    isActive,
    linkProps,
    updateIsActive,
  }
}
