<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && (!item.visible || (item.visible && item.visible()))"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      style="padding-left: 48px !important"
      class="d-flex align-items-center"
      :class="isActive ? 'bg-secondary' : ''"
    >
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->

      <font-awesome-icon
        v-if="item.icon != undefined"
        :icon="item.icon"
      />
      <span
        class="menu-title text-truncate"
        style="white-space: normal;"
      >{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="!isActive ? (item.tagVariant || 'primary') : (item.tagVariantActive || 'secondary')"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
      <b-badge
        v-if="isNovo"
        pill
        :variant="!isActive ? 'secondary' : 'primary'"
        class="mr-1 ml-auto"
      >
        Novo
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isNovo, isActive, linkProps, updateIsActive,
    } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isNovo,
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
