<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center">
      <baixa-lote />
      <feather-icon
        class="mr-25"
        icon="CalendarIcon"
        size="22"
      />
      <lista-observacoes />
      <feather-icon
        class="mr-1"
        icon="MailIcon"
        size="22"
      />
      <feather-icon
        class="mr-1"
        icon="CheckSquareIcon"
        size="22"
      />
      <feather-icon
        v-b-tooltip.hover
        title="Acessar empresas favoritas"
        icon="StarIcon"
        class="text-warning mr-1"
        size="22"
        @click="empresasFavoritas"
      />
      <certificado-vencido />
    </b-navbar-nav>
    <b-navbar-brand
      class="ml-auto mr-auto"
      style="cursor: pointer"
      @click="direcionaHome"
    >
      <img
        :src="require('@/assets/images/logo/logo.png')"
        class="d-inline-block logo-navbar"
        alt="monitor_contabil"
      >
    </b-navbar-brand>

    <b-navbar-nav
      class="nav align-items-center"
      style="margin-top: 20px; font-size: smaller;"
    >
      {{ tipoPlano }}
    </b-navbar-nav>

    <sistema-indisponivel />

    <b-navbar-nav class="nav align-items-center">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getNome() }}
            </p>
            <span class="user-status">{{ getEmpresaNome() }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="perfilUsuario"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-60"
          />
          <span style="margin: 7px;">Perfil usuário</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<style lang="scss">
.logo-navbar {
  width: 175px;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  border-radius: 2px;
  // background-color: #29282894;
  // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 80%);
}
</style>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { updateAbility, getUserInfo, getUserData } from '@/auth/utils'
import useAbility from '@/libs/acl/ability'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '@app/shared/mixins/event-bus/eventBus'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BaixaLote: () => import('@/layouts/components/baixa-lote/BaixaLote.vue'),
    ListaObservacoes: () => import('@/layouts/components/lista-observacoes/ListaObservacoes.vue'),
    CertificadoVencido: () => import('@/layouts/components/certificado-vencido/CertificadoVencido.vue'),
    SistemaIndisponivel: () => import('@/layouts/components/sistema-indisponivel/SistemaIndisponivel.vue'),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      tipoPlano: '',
    }
  },
  created() {
    EventBus.$on('atualizarPlano', novoPlano => this.atualizarPlano(novoPlano))
    this.setTipoPlano()
  },
  methods: {
    logout() {
      this.$router.push({ name: 'auth-login' })
    },
    getNome() {
      return getUserInfo().nome
    },
    direcionaHome() {
      this.$router.push('/')
    },
    getEmpresaNome() {
      return getUserInfo().empresa
    },
    perfilUsuario() {
      this.rota = window.location.pathname
      this.$router.push(`/usuario/editar/${getUserInfo().usuarioId}`)
      if (this.rota.match('/usuario/editar')) {
        window.location.reload()
      }
    },
    empresasFavoritas() {
      this.$router.push({ name: 'empresa-view', query: { filtroRapido: 'Favoritas' } })
    },
    setTipoPlano() {
      const userData = getUserData()
      // eslint-disable-next-line no-nested-ternary
      this.tipoPlano = userData.tipoPlano === 3 ? 'Plano Exclusivo' : userData.tipoPlano === 2 ? 'Plano Ideal' : 'Plano Essencial'
    },
    atualizarPlano(novoPlano) {
      const userData = getUserData()
      userData.tipoPlano = novoPlano
      useJwt.setUserData(userData)
      updateAbility(useAbility)
      this.setTipoPlano()
    },
  },
}
</script>
